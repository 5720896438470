import logo from './logo.svg';
import victoria from './victoria.jpeg';
import image111 from './111.jpg';
import twos from './222222222.jpg';
import three33 from './3333333333three.jpg';
import fourth1 from './img3333.jpg';
import moment from 'moment';

import './App.css';

function App() {


  const clue = (clueObject) => {
    const eventDate = moment("2021-09-29");
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const daysUntil = eventDate.diff(currentDate, 'days') - clueObject.clueDate;
    if (daysUntil > 0) {
      return (
      <div className="clue-text">
        <span>{daysUntil} {daysUntil === 1 ? 'more day for this clue' : 'more days for this clue'}</span>
      </div>
      )
    }
    return (
      <div className="clue">
        {clueObject.clueImage != '' && <img className="clueimage" src={clueObject.clueImage} alt='clueimage'/>}
      </div>
    )
  }

  const clueList = [
    {clueImage: image111, clueDate: 4},
    {clueImage: twos, clueDate: 3},
    {clueImage: three33, clueDate: 2},
    {clueImage: fourth1, clueDate: 1},
  ];



  // const theDate 


  return (
    <div className="App">
      <header className="App-header">
        <div className='prompt'>
      <span>Happy Birthday!  Can you guess your present?
         </span>
         </div>
        <img src={victoria} className="App-logo" alt="logo" />

        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>

      </header>
      <div className='clues'>
          {clueList.map(clueImage => clue(clueImage))}
        </div>
    </div>
  );
}

export default App;
